import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/authentication.guard';

const routes: Routes = [
  { path: '', redirectTo: 'Sign-in', pathMatch: 'full' },
  { path: 'Sign-in', loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) , canActivate: [AuthenticationGuard] },
  { path: 'import-events', loadChildren: () => import('./import-events/import-events.module').then(m => m.ImportEventsModule) , canActivate: [AuthenticationGuard]  },
  { path: 'bookmaker', loadChildren: () => import('./bookmaker/bookmaker.module').then(m => m.BookmakerModule) , canActivate: [AuthenticationGuard]  },
  { path: 'fancy', loadChildren: () => import('./fancy/fancy.module').then(m => m.FancyModule) , canActivate: [AuthenticationGuard]  },
  { path: 'bookmakerInfo', loadChildren: () => import('./bookmaker-info/bookmaker-info.module').then(m => m.BookmakerInfoModule) , canActivate: [AuthenticationGuard]  },
  { path: 'updatebookmakerInfo/:id', loadChildren: () => import('./bookmaker-info/bookmaker-info.module').then(m => m.BookmakerInfoModule) , canActivate: [AuthenticationGuard]  },
  { path: 'fancyInfo', loadChildren: () => import('./fancy-info/fancy-info.module').then(m => m.FancyInfoModule) , canActivate: [AuthenticationGuard]  },
  { path: 'updatefancyInfo/:id', loadChildren: () => import('./fancy-info/fancy-info.module').then(m => m.FancyInfoModule) , canActivate: [AuthenticationGuard]  },
  { path: 'bookmaker-rate/:id', loadChildren: () => import('./bookmaker-rate/bookmaker-rate.module').then(m => m.BookmakerRateModule) , canActivate: [AuthenticationGuard]  },
  { path: 'fancy-rate/:id', loadChildren: () => import('./fancy-rate/fancy-rate.module').then(m => m.FancyRateModule) , canActivate: [AuthenticationGuard]  },
  { path: 'admin/role', loadChildren: () => import('./admin-role/admin-role.module').then(m => m.AdminRoleModule) , canActivate: [AuthenticationGuard]  },
  { path: 'role-Info', loadChildren: () => import('./role-info/role-info.module').then(m => m.RoleInfoModule) , canActivate: [AuthenticationGuard]  },
  { path: 'updaterole-Info/:id', loadChildren: () => import('./role-info/role-info.module').then(m => m.RoleInfoModule) , canActivate: [AuthenticationGuard]  },
  { path: 'admin/user', loadChildren: () => import('./admin-user/admin-user.module').then(m => m.AdminUserModule) , canActivate: [AuthenticationGuard]  },
  { path: 'role-User', loadChildren: () => import('./role-user/role-user.module').then(m => m.RoleUserModule) , canActivate: [AuthenticationGuard]  },
  { path: 'updateUser/:id', loadChildren: () => import('./role-user/role-user.module').then(m => m.RoleUserModule) , canActivate: [AuthenticationGuard]  },
  { path: 'dataWash', loadChildren: () => import('./datawash/datawash.module').then(m => m.DatawashModule) , canActivate: [AuthenticationGuard]  },
  { path: 'logmatch', loadChildren: () => import('./log-match-list/log-match-list.module').then(m => m.LogMatchListModule) , canActivate: [AuthenticationGuard]  },
  { path: 'logmarket', loadChildren: () => import('./log-market-list/log-market-list.module').then(m => m.LogMarketListModule) , canActivate: [AuthenticationGuard]  },
  { path: 'loghistory', loadChildren: () => import('./log-history/log-history.module').then(m => m.LogHistoryModule) , canActivate: [AuthenticationGuard]  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
