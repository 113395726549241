import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'franks-port-admin';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];


  ngOnInit(): void {
    this.offlineEvent = fromEvent(window, 'offline');
    this.onlineEvent = fromEvent(window, 'online');
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      Swal.fire({
        // html:'<img src="../../assets/images/noConnection.jpeg"  height="42" width="42">',
        title: "Connection lost!",
        text: "You are not connected to internet",
        icon:'error',
        showConfirmButton:false,
        allowOutsideClick:false
      });
    }));
   
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      Swal.close();
    }));
    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
